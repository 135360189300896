import { Controller } from "@hotwired/stimulus"

const COPY_NEXT = "Next"
const COPY_SAVE = "Save"

// Connects to data-controller="ab--confirmable-modal"
export default class extends Controller {
  static targets = [
    "confirmableForm",
    "confirmationScreen",
    "confirmationScreenSequential",
    "confirmationScreenSignificantChanges",
    "saveButton",
    "disableSequential",
    "sequentialCheckbox"
  ]

  static outlets = ["ab--change-tracker"]

  static values = {
    confirmed: { type: Boolean, default: true },
    sequentialEnabled: { type: Boolean, default: false }
  }

  connect() {
    this.sequentialValueChanged()
    this.significantValueChanged()
    this.setConfirmedValue()
  }

  formSubmit(e) {
    if (this.hasConfirmationScreenTarget) {
      if (!this.confirmedValue) {
        e.preventDefault()
        e.stopImmediatePropagation()
        this.confirmationScreen()
      }
    }
  }

  confirmedSubmit() {
    this.confirmedValue = true
  }

  revertSubmit() {
    this.confirmableFormTarget.classList.remove("d-none")
    this.confirmationScreenTarget.classList.add("d-none")
  }

  confirmationScreen() {
    this.confirmableFormTarget.classList.add("d-none")
    this.confirmationScreenTarget.classList.remove("d-none")
  }

  confirmedValueChanged() {
    if (!this.shouldUpdateCopy()) { return }

    this.saveButtonTarget.value = this.confirmedValue ? COPY_SAVE : COPY_NEXT
  }

  valueChanged(event) {
    const namespaces = event.currentTarget.classList

    if (namespaces.contains('tracking-sequential') && this.sequentialEnabledValue) {
      this.sequentialValueChanged()
    }

    if (namespaces.contains('tracking-significant')) {
      this.significantValueChanged()
    }

    this.setConfirmedValue()
  }

  significantValueChanged() {
    if (this.hasConfirmationScreenSignificantChangesTarget) {
      this.confirmationScreenSignificantChangesTarget.classList.toggle("d-none", !this.hasSignificantChange())
    }
  }

  sequentialValueChanged() {
    if (this.hasConfirmationScreenSequentialTarget) {
      this.confirmationScreenSequentialTarget.classList.toggle("d-none", !this.hasSequentialChange())
    }

    // For hypothesis and treatments forms we added a hidden form element
    if (this.hasDisableSequentialTarget) {
      this.disableSequentialTarget.value = this.hasSequentialChange()
    }

    // For sizing we modify the sequential checkbox directly
    if (this.sequentialEnabledValue) {
      if (this.hasSequentialCheckboxTarget) {
        this.sequentialCheckboxTarget.checked = !this.hasSequentialChange()
        this.sequentialCheckboxTarget.disabled = this.hasSequentialChange() // We disable it so they can't enable it manually
      }
    }
  }

  setConfirmedValue() {
    this.confirmedValue = !this.hasSignificantChange() && !this.hasSequentialChange()
  }

  hasSignificantChange() {
    return this.abChangeTrackerOutlets.some((tracker) => {
      return tracker.getTrackingClasses().includes('tracking-significant') && tracker.hasChanged()
    })
  }

  hasSequentialChange() {
    return this.sequentialEnabledValue && this.abChangeTrackerOutlets.some((tracker) => {
      return tracker.getTrackingClasses().includes('tracking-sequential') && tracker.hasChanged()
    })
  }

  shouldUpdateCopy() {
    return this.hasSaveButtonTarget && this.hasConfirmationScreenTarget
  }
}
