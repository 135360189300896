import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }
  static targets = ['emptyOverrideAnswer', 'originalAnswer', 'overrideAnswer', 'overrideComment']

  changeAnswer(e) {
    const data = {
      'target_id': e.target.id,
      [this.overrideAnswerTarget.name]: this.overrideAnswerTarget.value,
    }
    if (this.hasEmptyOverrideAnswerTarget) data[this.emptyOverrideAnswerTarget.name] = false

    this.#overrideJudgment(data)
  }

  changeComment(e) {
    const data = {
      'target_id': e.target.id,
      [this.overrideCommentTarget.name]: this.overrideCommentTarget.value,
    }

    this.#overrideJudgment(data)
  }

  removeAnswer (e) {
    const data = {
      'target_id': e.target.id,
      [this.emptyOverrideAnswerTarget.name]: !!this.emptyOverrideAnswerTarget.checked,
      [this.overrideAnswerTarget.name]: ''
    }

    this.#strikeOriginalAnswer()
    this.#toggleDisabledTextarea()
    this.#overrideJudgment(data)
  }

  #strikeOriginalAnswer () {
    if (!this.hasOriginalAnswerTarget) return

    this.originalAnswerTarget.classList.toggle('struck')
  }

  #toggleDisabledTextarea () {
    if (!this.hasOverrideAnswerTarget) return

    this.overrideAnswerTarget.value = ''
    this.overrideAnswerTarget.disabled = !this.overrideAnswerTarget.disabled
  }

  #overrideJudgment (data) {
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => formData.append(key, value))

    patch(this.urlValue, { responseKind: 'turbo-stream', body: formData })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText)
      })
      .catch(() => alert('There was a problem saving your override.'))
  }
}
