import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ab--change-tracker"
export default class extends Controller {
  static values = {
    initial: String,
    alwaysChanged: { type: Boolean, default: false },
  }

  connect() {
    this.initialValue = this.element.value
  }

  hasChanged() {
    if (this.alwaysChangedValue) { return true }

    return this.element.value != this.initialValue
  }

  getTrackingClasses() {
    return Array.from(this.element.classList).filter((item) => item.startsWith('tracking'))
  }
}
