import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['refresh', 'initTrigger']
  static values = {
    allowBlankUpdates: Boolean,
    refreshTargetId: String,
    remoteUrl: String,
    path: String,
    params: Object
  }

  connect() {
    this.initTriggerTargets.forEach((element) => {
      const value = $(element).val();

      if (value != "" ) {
        this.makeRequest(value);
      }
    })
  }

  refreshTargetConnected(element) {
    if (element.id === '') {
      element.id = `df-${crypto.getRandomValues(new Uint32Array(1))}`
    }
  }

  submit(event) {
    const value = $(event.target).val()

    this.makeRequest(value);
  }

  makeRequest(value) {
    if (value == "" && !this.allowBlankUpdatesValue) {
      return
    }

    let params = new URLSearchParams()
    let url = this.remoteUrlValue

    if (this.pathValue) {
      url = url.replace(this.pathValue, value)
    }

    if (this.paramsValue) {
      for (const [key, value] of Object.entries(this.paramsValue)) {
        params.append(key, value)
      }
    }

    if (this.refreshTargetIdValue) {
      params.append('target', this.refreshTargetIdValue)
    } else if (this.hasRefreshTarget) {
      params.append('target', this.refreshTarget.id)
    }

    get(url, {
      responseKind: 'turbo-stream',
      query: Object.fromEntries(params)
    })
  }
}
